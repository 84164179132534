<template>
    <span>
        <v-tooltip v-if="isBranch" top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon
                       @click="$router.push({name: 'branch-inspections', params: {branch_id: item.id, branch: item}})">
                    <v-icon small>mdi-text-box-check-outline</v-icon>
                </v-btn>
            </template>
            <span>
                {{ $vuetify.lang.t('$vuetify.general.components.viewInspections') }}
            </span>
        </v-tooltip>

        <v-tooltip v-if="isGroup" top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon
                       @click="$router.push({name: 'group-members', params: {group_id: item.id, group: item}})">
                    <v-icon small>mdi-account-search</v-icon>
                </v-btn>
            </template>
            <span>
                {{ $vuetify.lang.t('$vuetify.general.components.viewMembers') }}
            </span>
        </v-tooltip>

        <v-dialog v-if="isInspection && item.result_id" height="517" hide-overlay width="260">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon small>mdi-text-box-check-outline</v-icon>
                </v-btn>
            </template>
            <kurcc-form-preview :inspection="item"/>
        </v-dialog>

        <v-tooltip v-if="isGroup" top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon
                       @click="$router.push({name: 'group-inspections', params: {group_id: item.id, group: item}})">
                    <v-icon small>mdi-text-box-check-outline</v-icon>
                </v-btn>
            </template>
            <span>
                {{ $vuetify.lang.t('$vuetify.general.components.viewInspections') }}
            </span>
        </v-tooltip>

        <v-tooltip v-if="isSite && !isVerificationsPage" top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon
                       @click="$router.push({name: 'site', params: {id: item.id, site: item}})">
                    <v-icon small>mdi-pencil</v-icon>
                </v-btn>
            </template>
            <span>
                {{ $vuetify.lang.t('$vuetify.general.components.editSite') }}
            </span>
        </v-tooltip>

        <v-tooltip v-if="isSite && !isVerificationsPage" top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon
                       @click="$router.push({name: 'site-inspections', params: {site_id: item.id, site: item}})">
                    <v-icon small>mdi-text-box-check-outline</v-icon>
                </v-btn>
            </template>
            <span>
                {{ $vuetify.lang.t('$vuetify.general.components.viewInspections') }}
            </span>
        </v-tooltip>

        <v-tooltip v-if="isSite" top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon
                       @click="$router.push({name: isVerificationsPage ? 'site-unverified-branches' : 'site-branches', params: {site_id: item.id, site: item}})">
                    <v-icon small>mdi-source-branch</v-icon>
                </v-btn>
            </template>
            <span>
                {{
                    isVerificationsPage ? $vuetify.lang.t('$vuetify.general.components.viewUnverifiedBranches') : $vuetify.lang.t('$vuetify.general.components.viewBranches')
                }}
            </span>
        </v-tooltip>

        <v-tooltip v-if="isSite && isVerificationsPage || isBranch && isBranchVerificationsPage" top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon
                       @click="gotoVerificationPage">
                    <v-icon small>mdi-check-decagram</v-icon>
                </v-btn>
            </template>
            <span>
                {{ isSite ? $vuetify.lang.t('$vuetify.general.components.verifySite') : $vuetify.lang.t('$vuetify.general.components.verifyBranch') }}
            </span>
        </v-tooltip>

        <v-tooltip v-if="isForm" top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon
                       @click="$router.push({name: 'form-new', params: {cloned_form: getClonedForm()}})">
                    <v-icon small>mdi-content-duplicate</v-icon>
                </v-btn>
            </template>
            <span>
                {{ $vuetify.lang.t('$vuetify.general.components.clonedForm') }}
            </span>
        </v-tooltip>
    </span>
</template>

<script>
export default {
    name: "KurccDataTableCustomActionButtons",
    props: {
        item: {
            required: true
        }
    },
    components: {
        KurccFormPreview: () => import('@/modules/forms/components/KurccFormPreview')
    },
    computed: {
        isBranch() {
            return this.item.object === 'Branch'
        },
        isGroup() {
            return this.item.object === 'Group'
        },
        isSite() {
            return this.item.object === 'Site'
        },
        isForm() {
            return this.item.object === 'Form'
        },
        isInspection() {
            return this.item.object === 'Inspection'
        },
        isVerificationsPage() {
            return this.$route.name === 'verifications'
        },
        isBranchVerificationsPage() {
            return this.$route.name === 'site-unverified-branches'
        }
    },
    methods: {
        getClonedForm() {
            this.item.id = null
            return this.item
        },
        gotoVerificationPage() {
            if (this.isSite && this.isVerificationsPage) {
                this.$router.push({name: 'site-verification', params: {id: this.item.id, site: this.item}})
            } else if (this.isBranch && this.isBranchVerificationsPage) {
                this.$router.push({name: 'branch-verification', params: {branch_id: this.item.id, branch: this.item}})
            }
        }
    }
}
</script>
