var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.isBranch)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({name: 'branch-inspections', params: {branch_id: _vm.item.id, branch: _vm.item}})}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-text-box-check-outline")])],1)]}}],null,false,2488592503)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.general.components.viewInspections'))+" ")])]):_vm._e(),(_vm.isGroup)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({name: 'group-members', params: {group_id: _vm.item.id, group: _vm.item}})}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-search")])],1)]}}],null,false,3519045907)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.general.components.viewMembers'))+" ")])]):_vm._e(),(_vm.isInspection && _vm.item.result_id)?_c('v-dialog',{attrs:{"height":"517","hide-overlay":"","width":"260"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-text-box-check-outline")])],1)]}}],null,false,468032213)},[_c('kurcc-form-preview',{attrs:{"inspection":_vm.item}})],1):_vm._e(),(_vm.isGroup)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({name: 'group-inspections', params: {group_id: _vm.item.id, group: _vm.item}})}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-text-box-check-outline")])],1)]}}],null,false,1479514876)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.general.components.viewInspections'))+" ")])]):_vm._e(),(_vm.isSite && !_vm.isVerificationsPage)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({name: 'site', params: {id: _vm.item.id, site: _vm.item}})}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,false,1205847106)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.general.components.editSite'))+" ")])]):_vm._e(),(_vm.isSite && !_vm.isVerificationsPage)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({name: 'site-inspections', params: {site_id: _vm.item.id, site: _vm.item}})}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-text-box-check-outline")])],1)]}}],null,false,1944415496)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.general.components.viewInspections'))+" ")])]):_vm._e(),(_vm.isSite)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({name: _vm.isVerificationsPage ? 'site-unverified-branches' : 'site-branches', params: {site_id: _vm.item.id, site: _vm.item}})}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-source-branch")])],1)]}}],null,false,4204010258)},[_c('span',[_vm._v(" "+_vm._s(_vm.isVerificationsPage ? _vm.$vuetify.lang.t('$vuetify.general.components.viewUnverifiedBranches') : _vm.$vuetify.lang.t('$vuetify.general.components.viewBranches'))+" ")])]):_vm._e(),(_vm.isSite && _vm.isVerificationsPage || _vm.isBranch && _vm.isBranchVerificationsPage)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.gotoVerificationPage}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check-decagram")])],1)]}}],null,false,795540411)},[_c('span',[_vm._v(" "+_vm._s(_vm.isSite ? _vm.$vuetify.lang.t('$vuetify.general.components.verifySite') : _vm.$vuetify.lang.t('$vuetify.general.components.verifyBranch'))+" ")])]):_vm._e(),(_vm.isForm)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.$router.push({name: 'form-new', params: {cloned_form: _vm.getClonedForm()}})}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-duplicate")])],1)]}}],null,false,3011772305)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.general.components.clonedForm'))+" ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }